import React, { useEffect, useRef } from "react"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.webp"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.webp"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.webp"
import imageFourDesktop from "./images/imagesDesktop/imageFour.webp"
import { Link } from "gatsby"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageThreeMobile from "./images/imagesMobile/imageThree.webp"
import imageFourMobile from "./images/imagesMobile/imageFour.webp"


const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Seguimiento automatizado",
      description: "Automatiza el seguimiento de tus contactos a lo largo de su ciclo de vida. Envía notificaciones por WhatsApp después de una llamada de ventas, programa recordatorios para los agentes sobre cuándo volver a contactar a un cliente potencial, audita tus conversaciones en tiempo real y mucho más.",
      image: imageOneMobile,
      background: "#EEEAFB"
    },
    {
      title: "Utiliza marcadores inteligentes",
      description: "Realiza llamadas automatizadas a toda tu base de datos o determinados grupos de clientes potenciales segmentados según criterios específicos. Deriva solo las llamadas contestadas por una voz humana; categoriza lo números inexistentes o apagados para un segundo tratamiento.",
      image: imageTwoMobile,
      background: "#DEF9FB"
    },
    {
      title: "Automatiza el contacto",
      description: "Envía mensajes personalizados de seguimiento y programa llamadas automáticas para mantener el compromiso y acelerar el proceso de ventas. Mejora la eficiencia de tu equipo de ventas al mantener una comunicación constante con los clientes potenciales. ",
      image: imageFourMobile,
      background: "#EEEAFB"
    },
    {
      title: "Sincroniza la información de tus leads",
      description: "Integra tus bases de datos desde Salesforce, HubSpot, Zoho o más, y gestiona la información de tus leads de manera centralizada. Accede fácilmente al historial de interacciones con tus clientes, detalles de contacto y otra información relevante durante las llamadas de ventas y seguimiento.",
      image: imageThreeMobile,
      background: "#DEF9FB"
    },
  ]

  return (
    <section className="container-experience-contactCenter">
      <p className="container-experience-contactCenter-title">
        Toma el poder de tus leads y <TitleUnderlineOne underline="aumenta tus" /> <TitleUnderlineTwo underline="conversiones" /> en tus campañas de telemarketing y televentas

      </p>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-contactCenter-cardsOne">
        {/* una sola fila */}
        <section className="container-experience-contactCenter-cardsOne-card" style={{ background: "#EEEAFB", padding: "32px 0px 0px" }}>
          <section style={{ padding: "0px 32px" }}>
            <p className="container-experience-contactCenter-cardsOne-card-title">Seguimiento automatizado</p>
            <p className="container-experience-contactCenter-cardsOne-card-description">
              Automatiza el seguimiento de tus contactos a lo largo de su ciclo de vida. Envía notificaciones por WhatsApp después de una llamada de ventas, programa recordatorios para los agentes sobre cuándo volver a contactar a un cliente potencial, audita tus conversaciones en tiempo real y mucho más.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-contactCenter-cardsOne-card-image"
              src={imageOneDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-contactCenter-cardsOne-column">
          {/* columna */}
          <section className="container-experience-contactCenter-cardsOne-card" style={{ background: "#DEF9FB" }}>
            <section>
              <p className="container-experience-contactCenter-cardsOne-card-title">Utiliza marcadores inteligentes</p>
              <p className="container-experience-contactCenter-cardsOne-card-description">
                Realiza llamadas automatizadas a toda tu base de datos o determinados grupos de clientes potenciales segmentados según criterios específicos. Deriva solo las llamadas contestadas por una voz humana; categoriza lo números inexistentes o apagados para un segundo tratamiento.
              </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-contactCenter-cardsOne-card-image"
                src={imageTwoDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-contactCenter-cardsTwo">
        {/* una sola fila */}
        <section className="container-experience-contactCenter-cardsTwo-card" style={{ background: "#DEF9FB", padding: "32px 0px 0px" }}>
          <section style={{ padding: "0px 32px" }} >
            <p className="container-experience-contactCenter-cardsTwo-card-title">Sincroniza la información de tus leads</p>
            <p className="container-experience-contactCenter-cardsTwo-card-description">
              Integra tus bases de datos desde Salesforce, HubSpot, Zoho o más, y gestiona la información de tus leads de manera centralizada. Accede fácilmente al historial de interacciones con tus clientes, detalles de contacto y otra información relevante durante las llamadas de ventas y seguimiento.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-contactCenter-cardsTwo-card-image"
              src={imageThreeDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-contactCenter-cardsTwo-column">
          {/* columna */}
          <section className="container-experience-contactCenter-cardsTwo-card" style={{ background: "#EEEAFB", padding: "32px 32px 42px" }}>
            <section>
              <p className="container-experience-contactCenter-cardsTwo-card-title">Automatiza el contacto</p>
              <p className="container-experience-contactCenter-cardsTwo-card-description">
                Envía mensajes personalizados de seguimiento y programa llamadas automáticas para mantener el compromiso y acelerar el proceso de ventas. Mejora la eficiencia de tu equipo de ventas al mantener una comunicación constante con los clientes potenciales.
              </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-contactCenter-cardsTwo-card-image"
                src={imageFourDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>


      <div className="container-experience-contactCenter-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-contactCenter-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-contactCenter-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-contactCenter-cards-mobile-card-description">
                      {elemento.description}
                    </p>
                    <br />
                  </section>
                  <section>
                    <img
                      className="container-experience-contactCenter-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
