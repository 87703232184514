import React from "react"

export const TitleUnderlineContactCenterOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="457" height="10" viewBox="0 0 457 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M84.8006 9.29961C75.3737 8.33798 6.32013 9.13035 1.30216 8.83285C-2.79402 8.44323 3.7775 1.86013 7.58436 0.900757C8.91876 0.555763 10.4678 0.510387 14.0158 0.681884C19.6414 1.00061 92.3988 0.66638 92.3988 0.66638C93.3588 0.873239 96.2432 0.196734 96.7716 0.446577C98.436 1.03107 216.365 0.659373 226.422 0.663124C232.52 0.665596 227.695 1.04276 234.872 0.909163C250.165 0.64529 283.493 1.06827 295.081 0.746794C304.355 0.480726 306.355 0.475085 315.255 0.748154C324.351 0.996482 372.718 0.855843 378.249 0.411227C379.602 0.288842 380.641 0.337026 380.64 0.483266C380.64 0.515057 414.733 0.743626 414.89 0.858629C415.144 1.00577 416.183 1.07938 417.144 0.981041C418.929 0.834747 455.22 0.409818 455.787 0.837831C457.567 2.06492 452.549 7.24831 448.33 8.49871C444.012 9.83778 420.334 8.23448 401.294 8.98106L312.658 8.7505L293.659 9.19201C291.484 8.7456 283.109 9.82236 278.054 9.01606C275.918 8.69695 260.822 8.59909 257.488 8.91794C254.585 9.16202 177.362 8.24693 169.438 9.10274C155.325 8.3153 98.074 9.16848 84.8006 9.29961Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineContactCenterTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="354" height="10" viewBox="0 0 354 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M65.6761 9.23203C58.3759 8.27791 4.89454 9.12531 1.00845 8.83181C-2.16366 8.44546 2.93111 1.85714 5.88023 0.894735C6.91397 0.548679 8.11368 0.502068 10.8614 0.670737C15.2181 0.984975 71.5677 0.592766 71.5677 0.592766C72.311 0.79886 74.5455 0.120058 74.9545 0.369479C76.2431 0.952642 167.577 0.486966 175.367 0.482701C180.089 0.480313 176.352 0.861324 181.91 0.722006C193.755 0.445945 219.566 0.842363 228.541 0.511653C235.724 0.238195 237.273 0.230961 244.166 0.496935C251.21 0.738014 288.67 0.558829 292.954 0.109807C294.002 -0.0136557 294.806 0.0336999 294.806 0.17994C294.806 0.211731 321.21 0.413129 321.331 0.528007C321.528 0.674944 322.333 0.747724 323.077 0.648621C324.459 0.500906 352.567 0.0470556 353.005 0.474616C354.383 1.70029 350.493 6.88766 347.224 8.14142C343.878 9.48393 325.541 7.89949 310.795 8.66125L242.148 8.50132L227.433 8.95798C225.749 8.51331 219.262 9.59673 215.347 8.79446C213.693 8.47706 202.002 8.39123 199.419 8.71274C197.171 8.95913 137.364 8.10558 131.227 8.96771C120.297 8.19151 75.9562 9.09032 65.6761 9.23203Z" fill="#FFA700" />
    </svg>
  </span>
)